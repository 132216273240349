import { graphql, Link } from 'gatsby'
import type { FC } from 'react'

import { Layout } from '../components/Layout'
import { SEO } from '../components/Seo'
import { tags } from '../constants/routes'

interface TagProps {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          excerpt: string
          fields: {
            slug: string
          }
          frontmatter: {
            title: string
          }
        }
      }>
      totalCount: number
    }
  }
  pageContext: {
    tag: string
  }
}

const Tag: FC<TagProps> = ({ data, pageContext }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const plural = totalCount === 1 ? '' : 's'
  const tagHeader = `${totalCount} note${plural}/post${plural} tagged with "${tag}"`

  return (
    <Layout>
      <SEO keywords={['tag', tag]} title={tagHeader} />
      <h2>{tagHeader}</h2>
      <ul>
        {edges.map(({ node }) => {
          const { title } = node.frontmatter
          const { slug } = node.fields

          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
            </li>
          )
        })}
      </ul>
      <Link to={tags}>All tags</Link>
    </Layout>
  )
}

// eslint-disable-next-line import/no-default-export
export default Tag

// NOTE: cannot use the `useStaticQuery` hook because of the way Gatsby uses a technique from Relay
// NOTE: cannot use the `useStaticQuery` hook because of this: https://www.gatsbyjs.org/docs/page-query/#the-longer-answer
// NOTE: used by gatsby-node.js to inject data at build time
export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
